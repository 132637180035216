// Импортируем js-файлы

document.addEventListener("DOMContentLoaded",function(){let n=0,t=document.querySelector(".sns-navbar"),a=document.querySelector("#home-header"),d=a?a.offsetHeight:0,o=!1,r=!1,i="navbar-fixed";var e=document.createElement("style");e.textContent=`
        .navbar-fixed {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 1030;
            transition: transform 0.3s ease-in-out;
            background-color: #fff;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .navbar-fixed.navbar-hidden {
            transform: translateY(-100%);
        }
    `,document.head.appendChild(e),window.addEventListener("scroll",function(){r||(window.requestAnimationFrame(function(){var e=(window.pageYOffset||document.documentElement.scrollTop);e>d?(o||(t.classList.add(i),o=!0),e>n?t.classList.add("navbar-hidden"):t.classList.remove("navbar-hidden")):(t.classList.remove(i,"navbar-hidden"),o=!1),n=e,r=!1}),r=!0)},{passive:!0})});
document.addEventListener('DOMContentLoaded', function () {
    let lastScrollTop = 0;
    const navbar = document.querySelector('.sns-navbar');
    const header = document.querySelector('#home-header');
    const headerHeight = header.offsetHeight;
    let isFixed = false;
    let ticking = false;

    // Добавляем стили для фиксированного состояния
    const navbarFixedClass = 'navbar-fixed';
    const style = document.createElement('style');
    style.textContent = `
        .navbar-fixed {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 1030;
            transition: transform 0.3s ease-in-out;
            background-color: #fff;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .navbar-fixed.navbar-hidden {
            transform: translateY(-100%);
        }
    `;
    document.head.appendChild(style);

    function updateNavbar() {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (scrollTop > headerHeight) {
            if (!isFixed) {
                navbar.classList.add(navbarFixedClass);
                isFixed = true;
            }

            if (scrollTop > lastScrollTop) {
                navbar.classList.add('navbar-hidden');
            } else {
                navbar.classList.remove('navbar-hidden');
            }
        } else {
            navbar.classList.remove(navbarFixedClass, 'navbar-hidden');
            isFixed = false;
        }

        lastScrollTop = scrollTop;
        ticking = false;
    }

    window.addEventListener('scroll', function () {
        if (!ticking) {
            window.requestAnimationFrame(function () {
                updateNavbar();
            });
            ticking = true;
        }
    }, {passive: true});
});